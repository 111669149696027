export const reviewSlider = () => {
  let reviewSlider = new Swiper(".slider__swiper", {
    spaceBetween: 30,
    slidesPerView: 1,
    navigation: {
      nextEl: ".slider__button-next",
      prevEl: ".slider__button-prev",
    },
    breakpoints: {
      1200: {
        spaceBetween: 30,
        slidesPerView: 3.1,
      },
      575: {
          spaceBetween: 15,
          slidesPerView: 2,
      }
    },
  });
}
