"use strict";

// Connecting vendors (plugins)
import "./_vendor";

// Functions
import { reviewSlider }     from "./components/reviewSlider";
import { mobileCheck } from "./functions/";
import { burger } from "./functions/";
import { accordion } from "./functions/";

// Components
// import { formValidation } from "./components/";

window.addEventListener("DOMContentLoaded", () => {
  reviewSlider();
  mobileCheck();
  accordion(".faq__body", "faq__question", ".faq__wrapper", "active");
});
